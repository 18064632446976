<template>
  <div class="nav-form">
    <div class="d-md-none">
      <b-link class="lnr lnr-magnifier" @click.prevent="toggleSearch"></b-link>
    </div>
    <div class="search-group" :class="{ show: isSearchGroupShown }">
      <b-btn v-on:click="goClose()" class="close-btn" variant="light" squared>X</b-btn>
      <b-form-input v-model="search" type="text" required @blur="hideSearchResultsTimer()"
        :placeholder="$t('search_placeholder')" v-on:keyup.enter="goSearch()"
        class="search-input float-right"></b-form-input>
      <b-btn v-on:click="goSearch()" class="search-btn" variant="light" squared>
        <i class="lnr lnr-magnifier font-weight-bold text-info"></i></b-btn>

      <div class="search-results" :class="{ show: isSearchResultsShown }">
        <span class="search--title">{{ $t("suggestions") }}:</span>
        <ul class="search-suggestions">
          <li v-for="suggestion of searchSuggestions" :key="suggestion.word" class="d-block">
            <b-link v-on:click="goRoute('/search', suggestion.word)">{{ suggestion.word }}</b-link>
          </li>
        </ul>
        <span class="search--title">{{ $t("recommended_products") }}:</span>
        <ul class="recommended-products">
          <li v-for="recommendedProduct of recommendedProducts" :key="recommendedProduct.sku" class="d-inline-block">
            <b-link v-on:click="goRoute('/' + recommendedProduct.url_key)" class="d-flex align-items-center">
              <div class="product-img">
                <b-img :src="recommendedProduct.image.small" :alt="recommendedProduct.name" width="41" height="51"
                  fluid></b-img>
              </div>
              <span class="product-name">{{ recommendedProduct.name }}</span>
            </b-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductsBySearchPreview } from "@storefront/core/data-resolver/products";

export default {
  name: "HeaderSearch",
  data: () => ({
    search: "",
    searchlTimer: null,
    seachCloseTimer: null,
    closeSignal: null,
    output: null,
    searchSuggestions: [],
    recommendedProducts: [],
    isSearchGroupShown: false,
    isSearchResultsShown: false,
  }),
  mounted() {
    window.addEventListener("resize", this.resize);
    document.addEventListener("click", this.close);
  },
  destroyed() {
    document.removeEventListener("click", this.close);
    window.removeEventListener("click", this.resize);
  },
  methods: {
    resize() {
      document.body.classList.add("resize-active");
      if (window.innerWidth >= 768) {
        this.isSearchGroupShown = false;
      }
      clearTimeout(this.resizeTimer);
      this.resizeTimer();
    },
    resizeTimer() {
      setTimeout(() => {
        document.body.classList.remove("resize-active");
      }, 400);
    },
    toggleSearch() {
      this.isSearchGroupShown = !this.isSearchGroupShown;
    },
    close(e) {
      if (
        !this.$el.parentElement ||
        !this.$el.parentElement.contains(e.target)
      ) {
        this.isSearchGroupShown = false;
      }
    },
    showSearchResults() {
      this.isSearchResultsShown = true;
    },
    hideSearchResults() {
      this.isSearchResultsShown = false;
    },
    async getSearchPreview() {
      const controller = new AbortController();
      const { signal } = controller;
      this.closeSignal = controller;

      this.output = await getProductsBySearchPreview(this.search, signal);
      this.closeSignal = null;

      this.recommendedProducts = this.output.items;
      this.searchSuggestions = this.output.search_suggestions;
      this.showSearchResults();
    },
    goRoute(route, query = null) {
      this.hideSearchResults();
      this.search = "";
      if (query != null) {
        this.$router.push({ path: route, query: { q: query } });
      } else {
        this.$router.push({ path: route });
      }
    },
    goSearch() {
      this.hideSearchResults();
      if (this.search.length > 0) {
        clearTimeout(this.searchlTimer);
        if (this.closeSignal !== null) this.closeSignal.abort();
        this.$router.push({ path: "/search", query: { q: this.search } });
      }
    },
    goClose() {
      this.close(this);
    },
    hideSearchResultsTimer() {
      this.seachCloseTimer = setTimeout(
        function (scope) {
          scope.search = "";
          scope.hideSearchResults();
        },
        300,
        this
      );
    },
  },
  watch: {
    search() {
      if (this.search.length > 2) {
        if (this.searchlTimer !== null) {
          clearTimeout(this.searchlTimer);
        }
        this.searchlTimer = setTimeout(
          function (scope) {
            if (scope.closeSignal !== null) {
              scope.closeSignal.abort();
            }
            scope.getSearchPreview();
          },
          300,
          this
        );
      } else {
        this.hideSearchResults();
      }
    },
  },
};
</script>
