<template>
  <div v-if="!isCookieAgreed" class="cookie-bar-wrap">
    <b-container>
      <div class="cookie-wrap">
        <div class="img">
          <b-img :src="require('@/esf_kerkrade_vitanatura/assets/images/header/logo.png')" :srcset="require('!!url-loader?limit=0!@/esf_kerkrade_vitanatura/assets/images/header/logo.png') +
            ' 253w ,' +
            require('!!url-loader?limit=0!@/esf_kerkrade_vitanatura/assets/images/header/logo.png') +
            ' 175w, ' +
            require('!!url-loader?limit=0!@/esf_kerkrade_vitanatura/assets/images/header/logo.png') +
            ' 75w '
            " width="180" height="57" alt="Logo"></b-img>
        </div>
        <div class="text">
          <strong class="title">{{ $t("cookiebar_title") }}</strong>
          <p>
            {{ $t("cookiebar_text") }}
          </p>
          <div class="sub-wrap">
            <div class="checkbox-block">
              <label>
                <input type="checkbox" checked="checked" name="check01" disabled />
                <span class="fake-check">{{ $t("cookiebar_necessary") }}</span>
              </label>
              <label>
                <input type="checkbox" v-model="allCookies" name="check02" />
                <span class="fake-check">{{ $t("cookiebar_other") }}</span>
              </label>
            </div>
            <div class="btn-wrap">
              <b-link class="btn btn-warning text-white px-60 py-5" @click="onClick">{{
                $t("cookiebar_accept")
                }}</b-link>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
//import { isServer } from "@storefront/core/helpers";

export default {
  name: "CookieBarWrap",
  components: {},
  data() {
    return {
      allCookies: true,
      isCookieAgreed: this.$cookies.isKey("websiteCookie"),
    };
  },
  methods: {
    onClick() {
      this.isCookieAgreed = true;
      this.$cookies.config("180d");
      this.$cookies.set("websiteCookie", this.allCookies);
      if (this.allCookies == true) {
        this.$gtm.enable(true);
      }
    },
  },
};
</script>
