<template>
  <ul class="top-icons">
    <template v-for="icon of icons" >
    <li :key="icon.iconNumber" class="d-flex" v-if="icon.isEnabled">

      <b-link class="d-flex" v-if="icon.isEnabled" :to="icon.link" @click.prevent="onClick"
        v-b-toggle="`topIconsCollapse` + showType + icon.iconNumber">

        <i v-if="icon.isLinearIcon" class="lnr text-info" :class="icon.iconName"></i>

        <div class="img" v-else>
          <b-img :src="icon.iconName" width="25" height="25" :alt="icon.text" fluid></b-img>
        </div>

        <span v-if="icon.showText" class="text-info" v-text="icon.text"></span>

        <b-badge v-if="icon.hasBadge" :variant="icon.badgeColor">{{ icon.badgeValue }}</b-badge>

      </b-link>

      <HeaderIconsDropdownCart v-if="icon.hasDropdown && icon.menuId == 'cart' && icon.isEnabled == true" :icon="icon"
        :showType="showType" />

      <HeaderIconsDropdown v-if="icon.hasDropdown && icon.menuId == 'wishList' && icon.isEnabled == true" :icon="icon"
        :showType="showType" />

      <HeaderIconsDropdownMenu v-if="icon.hasDropdown && icon.menuId == 'account' && icon.isEnabled == true"
        :icon="icon" :showType="showType" />

    </li>
  </template>
  </ul>
</template>
<script>
import HeaderIconsDropdown from "@/base/core/components/HeaderIconsDropdown";
import HeaderIconsDropdownCart from "@/esf_kerkrade_vitanatura/core/components/HeaderIconsDropdownCart";
import HeaderIconsDropdownMenu from "@/base/core/components/HeaderIconsDropdownMenu";
import Cart from "@storefront/core/modules/cart/mixins";
export default {
  name: "HeaderTopIcons",
  mixins: [Cart],
  components: {
    HeaderIconsDropdown,
    HeaderIconsDropdownCart,
    HeaderIconsDropdownMenu,
  },
  methods: {
    onClick() { },
  },
  props: {
    showType: { type: String },
  },
  computed: {
    icons() {
      return [
        {
          iconNumber: 1,
          isLinearIcon: true,
          hasBadge: true,
          badgeValue: this.cartCount,
          badgeColor: "success",
          iconName: "lnr-cart",
          text: "Winkelmand",
          isEnabled: true,
          hasDropdown: true,
          // dropHeading: this.$t("items_in_cart_text1") + " <span class='text-warning'>" + this.cartCount + "</span> " + this.$t("items_in_cart_text2")
          //   .replace(/&lt;/g, "<")
          //   .replace(/&gt;/g, ">"),
          dropItems: this.cartItems,
          menuId: "cart",
          cartFooter: {
            totalPrice: this.cartSubTotalPrice,
            btnText: this.$t("goto_checkout"),
            btnVariant: "success",
          },
          link: "#",
          showText: false
        },
        {
          iconNumber: 2,
          isLinearIcon: true,
          hasBadge: false,
          iconName: "lnr-user",
          text: this.$t("login"),
          hasDropdown: false,
          link: "/login",
          isEnabled: !this.isLoggedIn,
          showText: true

        },
        {
          iconNumber: 3,
          isLinearIcon: true,
          hasBadge: false,
          iconName: "lnr-user",
          text: this.$t("account"),
          menuId: "account",
          hasDropdown: true,
          isEnabled: this.isLoggedIn,
          showText: false

        },
      ];
    },
  },
};
</script>
