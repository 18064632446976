<template>
    <div id="wrapper" :class="deviceClass">
    
        <Header />
    
        <main id="main" :class="wrapperClass">
    
            <slot></slot>
    
        </main>
    
        <Footer />
    
        <ClientOnly>
    
            <CookieBarWrap />
    
        </ClientOnly>
    
    </div>
</template>

<script>
import Header from "@/esf_kerkrade_vitanatura/core/components/Header.vue";
import Footer from "@/esf_kerkrade_vitanatura/core/components/Footer.vue";
import CookieBarWrap from "@/esf_kerkrade_vitanatura/core/components/CookieBar";
import ClientOnly from "vue-client-only";



export default {
    name: "AppLayout",
    components: {
        Header,
        Footer,
        CookieBarWrap,
        ClientOnly
    },
    props: {
        wrapperClass: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            deviceClass: 'desktop'
        };
    },
    mounted() {
        this.resizeWindow();
        window.addEventListener("resize", this.resizeWindow);
    },
    methods: {
        resizeWindow() {
            let windowWidth = window.innerWidth;
            if (windowWidth > 992) {
                this.deviceClass = 'desktop';
            } else {
                this.deviceClass = 'mobile';
            }
        }
    }
}
</script>

<style>
main#main {
    @media screen and (max-width: 1024px) {
        margin-top: 0px;
    }
    @media screen and (max-width: 991px) {
        margin-top: 101px;
    }
    @media screen and (max-width: 768px) {
        margin-top: 70px;
    }
    @media screen and (max-width: 425px) {
        margin-top: 51px;
    }
}
</style>